import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { Column, ColumnFormat } from '@core/interfaces/column'
import { ContextMenuItem } from '@core/interfaces/context-menu-item'
import { TableContextMenuSelectEvent, TableModule } from 'primeng/table'
import { ArticleStockDialogComponent } from '@app/components/dialogs/article-stock/article-stock-dialog.component'
import { DialogHelper } from '@core/common/dialog-helper'
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu'
import { NgForOf, NgIf } from '@angular/common'
import { TableHelper } from '@core/common/table-helper'
import { StringFieldComponent } from '@app/components/uiux/string-field/string-field.component'
import { MenuItem } from 'primeng/api'

@Component({
	standalone: true,
	selector: 'tb-items-overlay',
	templateUrl: './items-overlay.component.html',
	imports: [
		OverlayPanelModule,
		ContextMenuModule,
		TableModule,
		NgForOf,
		StringFieldComponent,
		NgIf
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsOverlayComponent implements OnChanges {

	@ViewChild('cm') contextMenu!: ContextMenu

	@Input({ required: true }) items: any

	protected columns: Column[] = [
		{ label: 'Nummer', field: row => this.getNumber(row) },
		{ label: 'Name', field: row => this.getName(row), format: ColumnFormat.String, maxLength: 40, tooltip: false },
		{ label: 'Menge', field: 'quantity', format: ColumnFormat.Integer, appendix: 'Stk.' },
		{ label: 'Preis (Netto)', field: row => this.getPriceNet(row), format: ColumnFormat.Currency },
	]

	protected selectedRow: any
	protected contextMenuSelectEvent: TableContextMenuSelectEvent | null = null
	protected contextMenuActions: MenuItem[] = []
	protected actions: ContextMenuItem[] = [
		{
			label: 'Artikel öffnen',
			icon: 'pi pi-fw pi-qrcode',
			command: () => this.openArticle(),
			condition(row: any): boolean {
				return row?.article || row?.orderItem?.article || row?.shipmentItem?.orderItem?.article
			}
		},
		{
			label: 'Artikelbestand',
			icon: 'pi pi-fw pi-info-circle',
			command: () => {
				this.dialogHelper.open(ArticleStockDialogComponent, { header: 'Artikelbestand' }, { id: this.getSelectedArticleId() })
			},
			condition(row: any): boolean {
				return row?.article || row?.orderItem?.article || row?.shipmentItem?.orderItem?.article
			}
		},
	]

	constructor(
		private cd: ChangeDetectorRef,
		private dialogHelper: DialogHelper,
		protected tableHelper: TableHelper,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		// check if items has key 'nextDeliveryDate' and add it to columns
		if (changes['items'] && this.items.length > 0 && typeof this.items[0].nextDeliveryDate !== 'undefined') {
			this.columns.push({ label: 'Nächstes Lieferdatum', field: 'nextDeliveryDate', format: ColumnFormat.Date })
		}

		this.cd.markForCheck()
	}

	protected openArticle(): void {
		if (this.getSelectedArticleId() > 0) {
			const articleUrl: string = `/goods/articles/${this.getSelectedArticleId()}`

			const win = window.open(location.origin + articleUrl, '_blank')
			if (win) {
				win.focus()
			}
		}
	}

	onContextMenu(event: any, row: any): void {
		// revert contextMenuActions to initial state
		this.contextMenuActions = []

		// process all actions passed to this component
		this.contextMenuActions = this.contextMenuActions.concat(this.tableHelper.processContextMenuActions(this.actions, row))

		if (this.contextMenuActions.length > 0) {
			this.selectedRow = row

			this.contextMenu.show(event)
		}

		event.preventDefault()
	}

	private getSelectedArticleId(): number {
		if (this.selectedRow?.shipmentItem?.orderItem?.article) {
			return this.selectedRow?.shipmentItem?.orderItem?.article?.id || 0
		} else if (this.selectedRow?.orderItem?.article) {
			return this.selectedRow?.orderItem?.article?.id || 0
		} else if (this.selectedRow?.article) {
			return this.selectedRow?.article?.id || 0
		}

		return 0
	}

	private getName(row: any): string {
		if (row?.shipmentItem?.orderItem?.article) {
			const article = row.shipmentItem.orderItem.article

			if (article.internalName && article.internalName.length > 0)
				return article.internalName
			else if (article.name && article.name.length > 0)
				return article.name
		} else if (row?.orderItem?.article) {
			const article = row.orderItem.article

			if (article.internalName && article.internalName.length > 0)
				return article.internalName
			else if (article.name && article.name.length > 0)
				return article.name
		} else if (row?.article) {
			const article = row.article

			if (article.internalName && article.internalName.length > 0)
				return article.internalName
			else if (article.name && article.name.length > 0)
				return article.name
		}

		return row.name || '-'
	}

	private getNumber(row: any): string {
		if (row?.shipmentItem?.orderItem?.article?.sku) {
			const article = row.shipmentItem.orderItem.article

			return article.sku
		} else if (row?.orderItem?.article?.sku) {
			return row.orderItem.article.sku
		} else if (row?.article?.sku) {
			return row.article.sku
		}

		return row.number || '-'
	}

	private getPriceNet(row: any): string {
		if (row?.shipmentItem?.orderItem?.priceNet) {
			return row.shipmentItem.orderItem.priceNet
		} else if (row?.orderItem?.priceNet) {
			return row.orderItem.priceNet
		} else if (row?.priceNet) {
			return row.priceNet
		}

		return '0'
	}

	protected readonly ColumnFormat = ColumnFormat
}
