<p-messages *ngIf="id <= 0" [(value)]="errorMessage" [enableService]="false" [closable]="false"></p-messages>

<div *ngIf="loading else loaded" class="justify-content-center">
	<tb-progress-spinner />
</div>

<ng-template #loaded>
	<div *ngIf="data" class="grid">
		<div class="col-4 text-center" style="border-right:1px solid #363956" pTooltip="Aktueller Bestand abzüglich defekter Ware, offener Aufträge, Aufträge im Versand und Reservierungen">
			<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.availableStock || 0) }}</span><br>
			<span>Verfügbarer Bestand</span>
		</div>

		<div class="col-4 text-center" style="border-right:1px solid #363956" pTooltip="Menge die sich in offenen Bestellungen befindet">
			<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.intakeStock || 0) }}</span><br>
			<span>Zulauf</span>
		</div>

		<div class="col-4 text-center" pTooltip="Verfügbarer Bestand plus Zulauf">
			<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.freelyAvailable || 0) }}</span><br>
			<span>Zur freien Verfügung</span>
		</div>
	</div>

	<div *ngIf="data" class="mt-4">
		<p-accordion>
			<p-accordionTab header="Weitere Informationen">
				<div class="grid">
					<div class="col-2 text-center" style="border-right:1px solid #363956" pTooltip="Vorhandene Menge in jedem Zustand (A, B, C, D)">
						<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.inStock || 0) }}</span><br>
						<span>Lagernd</span>
					</div>

					<div class="col-2 text-center" style="border-right:1px solid #363956" pTooltip="Menge die in einem Warenausgang erfasst, aber noch nicht versendet wurde">
						<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.inShipping || 0) }}</span><br>
						<span>Im Versand</span>
					</div>

					<div class="col-2 text-center" style="border-right:1px solid #363956" pTooltip="Menge in offenen Aufträgen">
						<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.salesQuantity || 0) }}</span><br>
						<span>In Aufträgen</span>
					</div>

					<div class="col-2 text-center" style="border-right:1px solid #363956" pTooltip="Defekte Artikel im Lager (Zustand: B, C oder D)">
						<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.brokenStock || 0) }}</span><br>
						<span>Beschädigt</span>
					</div>

					<div class="col-2 text-center" pTooltip="Menge die in einem Reservierungsauftrag hinterlegt ist">
						<span class="font-bold">{{ Formatter.formatIntNumber(data.stockInfo.reservedStock || 0) }}</span><br>
						<span>Reserviert</span>
					</div>
				</div>

				<div class="grid mt-4">
					<div class="col-4">
						<p-table [value]="data.storageLocationItems">
							<ng-template pTemplate="caption">Lagerplätze</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th>Lagerplatz (Partner)</th>
									<th>Menge</th>
									<th>Zustand</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>
								<tr>
									<td>{{ item.storageLocationName + ' (' + item.logisticsPartnerName + ')' }}</td>
									<td>{{ Formatter.formatIntNumber(item.quantity) }}</td>
									<td><tb-enum-field [value]="item.condition" enumName="Logistics\StorageLocationItemCondition" /></td>
								</tr>
							</ng-template>
						</p-table>
					</div>

					<div class="col-4">
						<p-table [value]="data.salesOrderItems">
							<ng-template pTemplate="caption">Aufträge</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th>Nummer</th>
									<th>Sendung</th>
									<th>Menge</th>
									<th>EK</th>
									<th>VK</th>
									<th>LT</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>
								<tr>
									<td><a routerLink="/sales/orders/{{ item.orderId }}" target="_blank">{{ item.orderNumber || '-' }} <i class="pi pi-link"></i></a></td>
									<td>{{ item.shipmentNumber || '-' }}</td>
									<td>{{ Formatter.formatIntNumber(item.quantity) }}</td>
									<td>{{ Formatter.formatCurrency(item.purchasePriceNet) }}</td>
									<td>{{ Formatter.formatCurrency(item.priceNet) }}</td>
									<td><tb-string-field [value]="calcDeliveryDatesString(item.deliveryDates)" [maxLength]="12" /></td>
								</tr>
							</ng-template>
						</p-table>
					</div>

					<div class="col-4">
						<p-table [value]="data.purchaseOrderItems">
							<ng-template pTemplate="caption">Bestellungen</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th>Nummer</th>
									<th>Sendung</th>
									<th>Menge</th>
									<th>Preis</th>
									<th>Lieferdatum</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>
								<tr>
									<td><a routerLink="/purchase/orders/{{ item.orderId }}" target="_blank">{{ item.orderNumber || '-' }} <i class="pi pi-link"></i></a></td>
									<td>{{ item.shipmentNumber || '-' }}</td>
									<td>{{ Formatter.formatIntNumber(item.quantity) }}</td>
									<td>{{ Formatter.formatCurrency(item.priceNet) }}</td>
									<td>{{ Formatter.formatDate(item.deliveryDate) }}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>

				<div *ngIf="storageHistoryList.length > 0" class="grid mt-4">
					<div class="col-12">
						<p-table
							[value]="storageHistoryList"
							[columns]="storageHistoryColumns"
							[paginator]="true"
							[rows]="200"
							[first]="0"
							[rowsPerPageOptions]="[ 200 ]"
							[totalRecords]="totalRecords"
							[rowHover]="true"
							[scrollable]="true"
							[showCurrentPageReport]="true"
							dataKey="id"
							responsiveLayout="scroll"
							styleClass="p-datatable-gridlines"
							currentPageReportTemplate="{first} - {last} von {totalRecords}"
						>
							<ng-template pTemplate="caption">Lagerhistorie</ng-template>

							<ng-template pTemplate="header" let-columns>
								<tr>
									<th *ngFor="let col of columns">
										{{ col.label }}
									</th>
								</tr>
							</ng-template>

							<ng-template pTemplate="body" let-rowData let-columns="columns">
								<tr>
									<td *ngFor="let col of columns">
										{{ tableHelper.formatTableEntry(col, rowData) }}
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
			</p-accordionTab>
		</p-accordion>
	</div>
</ng-template>

<div class="mt-3">
	<tb-btn
		label="Schließen"
		class="ml-1"
		[secondary]="true"
		(onClick)="close()"
	/>
</div>
